import { AspectRatio, Box, Flex } from '@chakra-ui/react';
import Teaser from './Teaser';
import Image from './Image';
import { AcfHeading, HeroProps } from '../types';

const mapTeaserHeroHeadingProps = (heading: AcfHeading) => ({
  title: heading.title,
  style: 'h2',
  importance: 'h2',
});

const TeaserHero = ({ heading, text, buttons, image }: HeroProps) => (
  <Box position="relative">
    <AspectRatio ratio={[343 / 253, null, 1440 / 640]}>
      <Image
        src={image?.src}
        alt={image?.alt || heading.title}
        layout="fill"
        borderBottomLeftRadius={['0', null, 'lg']}
        borderBottomRightRadius={['0', null, 'lg']}
      />
    </AspectRatio>
    <Flex
      p={8}
      pl={[8, 8, 8, '8.33333333%']}
      position={[null, null, 'absolute']}
      bg={['greenDarker', null, 'transparent']}
      borderBottomLeftRadius={['lg', null, 0]}
      borderBottomRightRadius={['lg', null, 0]}
      top="0"
      left="0"
      bottom="0"
      right="0"
      width="100%"
      alignItems="center"
    >
      <Box flex={[null, null, null, '0 0 75%', '0 0 50%']}>
        <Teaser
          heading={mapTeaserHeroHeadingProps(heading)}
          variant="white"
          text={text}
          textStyle="md"
          buttons={buttons && buttons?.map((btn) => ({ ...btn, size: 'xl' }))}
          invertedColors
        />
      </Box>
    </Flex>
  </Box>
);

export default TeaserHero;
